import { Component, FC, ReactElement, ReactNode } from 'react';
import { Environment, graphql, useRelayEnvironment } from 'react-relay';
import { getQueryParam } from '../../../utils/uriUtils';
import { SharedModifyPortfolioItems } from '../../../mutations/FindingSharedModifyPortfolioItems';
import { SharedParallelizedQueryRenderer } from '../../../shared/SharedParallelizedQueryRenderer';
import {
    locations,
    trackHeartFavorited,
    FavoritesLocation,
} from '../../../utils/tracking/shared/favoritesTracking';
import {
    getForceFavoriteItemId,
    clearForceFavoriteItemId,
    FORCE_FAVORITE_ITEM_ID,
} from './localStorageHelpers';
import { FavoritesProviderChildrenProps } from './FavoritesProviderChildrenProps';

import { FavoritesProviderQuery$data } from './__generated__/FavoritesProviderQuery.graphql';

type Props = {
    children: ({ props }: { props: FavoritesProviderChildrenProps }) => ReactNode;
    disable?: boolean;
    fetchFolder?: boolean;
    fetchHeart?: boolean;
    itemIds: string[];
    shouldAutoFavorite?: boolean;
    userId?: string | null;
    shouldRefetch?: boolean;
    shouldShowHeartCount?: boolean;
    environment: Environment;
    location: FavoritesLocation;
};

type State = {
    variables: {
        userIds: (string | null)[];
        selectedItemIds: (string | null)[];
        loadPortfolioData: boolean;
        shouldFillInFolder: boolean;
        shouldShowHeartCount: boolean;
    };
};

export class FavoritesProviderComponent extends Component<Props, State> {
    static defaultProps = {
        fetchHeart: true,
        fetchFolder: true,
        shouldAutoFavorite: false,
        shouldRefetch: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            variables: {
                userIds: [],
                selectedItemIds: [],
                loadPortfolioData: false,
                shouldFillInFolder: false,
                shouldShowHeartCount: false,
            },
        };
    }

    componentDidMount(): void {
        if (this.shouldPerformAutoFavorite()) {
            this.handleAutoFavorite();
        } else if (this.props.userId) {
            // Set refetch variables if user is logged in
            this.setVariables();
        } else if (this.props.itemIds.length && this.props.shouldShowHeartCount) {
            // Even if user is not logged in, refetch heart count data
            this.setState(state => ({
                variables: {
                    ...state.variables,
                    selectedItemIds: this.props.itemIds,
                    shouldShowHeartCount: true,
                },
            }));
        }
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.shouldPerformAutoFavorite(prevProps)) {
            this.handleAutoFavorite();
        } else if (
            (!prevProps.userId && this.props.userId) ||
            (this.props.userId && prevProps.itemIds.toString() !== this.props.itemIds.toString())
        ) {
            this.setVariables();
        }

        const forceFavoriteItemId = getForceFavoriteItemId(FORCE_FAVORITE_ITEM_ID) as string;

        if (this.props.itemIds.includes(forceFavoriteItemId) && this.props.userId) {
            this.handleAutoFavorite(forceFavoriteItemId);
            clearForceFavoriteItemId(FORCE_FAVORITE_ITEM_ID);
        }

        if (!prevProps.shouldRefetch && this.props.shouldRefetch) {
            this.setVariables();
        }
    }

    shouldPerformAutoFavorite(prevProps: Partial<Props> = {}): boolean {
        const hasAutoFavoriteQueryParam =
            getQueryParam(location.href, 'follow') === 'item' ||
            getQueryParam(location.href, 'save') === 'item';
        return (
            !!this.props.shouldAutoFavorite &&
            hasAutoFavoriteQueryParam &&
            !prevProps.userId &&
            !!this.props.userId
        );
    }

    handleAutoFavorite(favoriteItemId?: string): void {
        const { itemIds, environment, userId } = this.props;
        const itemId = favoriteItemId || itemIds[0];

        SharedModifyPortfolioItems.commit(environment, {
            action: 'ADD',
            itemId,
            portfolioType: 'HEART',
            userId: userId ?? '',
            onCompleted: () => {
                trackHeartFavorited({ location: locations.AUTO_EMAIL, itemPk: itemId });
                this.setVariables();
            },
        });
    }

    setVariables(): void {
        const { userId = '', itemIds, shouldShowHeartCount } = this.props;
        this.setState({
            variables: {
                userIds: [userId],
                selectedItemIds: itemIds,
                loadPortfolioData: true,
                shouldFillInFolder: true,
                shouldShowHeartCount: !!shouldShowHeartCount,
            },
        });
    }

    render(): ReactElement {
        const { environment } = this.props;

        return (
            <SharedParallelizedQueryRenderer
                query={graphql`
                    query FavoritesProviderQuery(
                        $loadPortfolioData: Boolean = false
                        $selectedItemIds: [String] = []
                        $userIds: [String] = []
                        $shouldFillInFolder: Boolean = false
                        $shouldShowHeartCount: Boolean = false
                    ) {
                        viewer {
                            ...FolderWrapper_viewer
                                @arguments(
                                    selectedItemIds: $selectedItemIds
                                    shouldFillInFolder: $shouldFillInFolder
                                    userIds: $userIds
                                )
                            ...HeartWrapper_viewer
                                @arguments(
                                    loadPortfolioData: $loadPortfolioData
                                    selectedItemIds: $selectedItemIds
                                    userIds: $userIds
                                    showHeartCount: $shouldShowHeartCount
                                )
                        }
                    }
                `}
                environment={environment}
                variables={this.state.variables}
                render={({ data }: { data: FavoritesProviderQuery$data }) => {
                    const { viewer = null } = data || {};
                    const { userId = '', itemIds, disable } = this.props;
                    const userIds = userId ? [userId] : [];

                    const childrenProps: FavoritesProviderChildrenProps = {
                        userId,
                        itemIds,
                        userIds,
                        disable,
                        location: this.props.location,
                        fetchHeart: !!this.props.fetchHeart,
                        fetchFolder: !!this.props.fetchFolder,
                        singlePortfolioItem: true,
                        loadPortfolioItemData: true,
                        viewer,
                    };

                    return this.props.children({ props: childrenProps });
                }}
            />
        );
    }
}

export const FavoritesProvider: FC<Omit<Props, 'environment'>> = ({ children, ...props }) => {
    const environment = useRelayEnvironment();
    return (
        <FavoritesProviderComponent {...props} environment={environment}>
            {children}
        </FavoritesProviderComponent>
    );
};
