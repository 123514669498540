/**
 * @generated SignedSource<<0438d259f0c3d67836681280e12ccf51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FavoritesItem_item$data = {
  readonly isAuctionEnabled: boolean | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "FavoritesItem_item";
};
export type FavoritesItem_item$key = {
  readonly " $data"?: FavoritesItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FavoritesItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAuctionEnabled",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "a51df215f7f70c956ed7c12f0771150f";

export default node;
